<template>
  <div
    id="app"
    class="app"
  >
    <nuxt-page />
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({})
</script>
